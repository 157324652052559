export interface IEnvironmentAddNetwork {
  chainId: string;
  rpcUrls: string[];
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrls: string[];
}

export interface IEnvironment {
  addNetworkSettings: IEnvironmentAddNetwork;
  auctionHouseAddress: string;
  backendHost: string;
  bannerLaunchDate: string;
  buildAddress: string;
  captchaSiteKey: string;
  cashierPaymentAddress: string;
  developmentMode: boolean;
  enableRampPayment: boolean;
  erc1155HandlerAddress: string;
  etherscanUrl: string;
  gasPriceMultiplier: number;
  golemRenderingEnabled: boolean;
  golemApiAddress: string;
  golemApiKey: string;
  googleTagManagerKey: string;
  hideLogin: boolean;
  kucoinExchangeCurrency: string;
  lootboxStoreAddress: string;
  lootboxOpen: boolean;
  networkId: string;
  rampHostApiKey: string;
  rampUrl: string;
  reservationInsteadOfNotify: boolean;
  showOpeningCounter: boolean;
  torus: string;
  tradeAddress: string;
  uniswapInputCurrency: string;
  uniswapOutputCurrency: string;
  uniswapDefaultExchangeAmount: number;
  useMarketMocks: boolean;
  disableAvatarUpload: boolean;
  redeemActive: boolean;
  redeemCampaignId: string;
  redeemGleamUrl: string;
  redeemNftId: string;
  redeemNftShares: number;
  royaltiesFee: number;
  tradeFee: number;
}

export const useEnv = (): IEnvironment => {
  const environmentsVariables = process.env;

  return {
    addNetworkSettings: JSON.parse(environmentsVariables.REACT_APP_ADD_NETWORK_SETTINGS!),
    auctionHouseAddress: environmentsVariables.REACT_APP_AUCTION_HOUSE_ADDRESS!,
    backendHost: environmentsVariables.REACT_APP_BACKEND_HOST!,
    bannerLaunchDate: environmentsVariables.REACT_APP_BANNER_LAUNCH_DATE!,
    buildAddress: environmentsVariables.REACT_APP_BUILD_ADDRESS!,
    captchaSiteKey: environmentsVariables.REACT_APP_CAPTCHA_SITE_KEY!,
    cashierPaymentAddress: environmentsVariables.REACT_APP_CASHIER_PAYMENT_ADDRESS!,
    developmentMode: environmentsVariables.REACT_APP_DEVELOPMENT_MODE?.toLowerCase() === 'true',
    enableRampPayment: environmentsVariables.REACT_APP_ENABLE_RAMP_PAYMENT?.toLowerCase() === 'true',
    erc1155HandlerAddress: environmentsVariables.REACT_APP_ERC1155_HANDLER_ADDRESS!,
    etherscanUrl: environmentsVariables.REACT_APP_ETHERSCAN_URL!,
    gasPriceMultiplier: +environmentsVariables.REACT_APP_GAS_PRICE_MULTIPLIER!,
    golemRenderingEnabled: environmentsVariables.REACT_APP_GOLEM_RENDERING_ENABLED?.toLowerCase() === 'true',
    golemApiAddress: environmentsVariables.REACT_APP_GOLEM_API_ADDRESS!,
    golemApiKey: environmentsVariables.GOLEM_API_KEY!,
    googleTagManagerKey: environmentsVariables.REACT_APP_GOOGLE_TAG_MANAGER_KEY!,
    hideLogin: environmentsVariables.REACT_APP_HIDE_LOGIN!?.toLowerCase() === 'true',
    kucoinExchangeCurrency: environmentsVariables.REACT_APP_KUCOIN_EXCHANGE_CURRENCIES!,
    lootboxStoreAddress: environmentsVariables.REACT_APP_LOOTBOX_STORE_ADDRESS!,
    lootboxOpen: environmentsVariables.REACT_APP_LOOTBOX_OPEN!?.toLowerCase() === 'true',
    networkId: environmentsVariables.REACT_APP_NETWORK_ID!,
    rampHostApiKey: environmentsVariables.REACT_APP_RAMP_HOST_API_KEY!,
    rampUrl: environmentsVariables.REACT_APP_RAMP_URL!,
    redeemActive: environmentsVariables.REACT_APP_REDEEM_ACTIVE === 'true',
    redeemCampaignId: environmentsVariables.REACT_APP_REDEEM_CAMPAIGN_ID!,
    redeemGleamUrl: environmentsVariables.REACT_APP_REDEEM_GLEAM_URL!,
    redeemNftId: environmentsVariables.REACT_APP_REDEEM_NFT_ID!,
    redeemNftShares: +environmentsVariables.REACT_APP_REDEEM_NFT_SHARES!,
    reservationInsteadOfNotify: environmentsVariables.REACT_APP_RESERVATION_INSTEAD_OF_NOTIFY?.toLowerCase() === 'true',
    showOpeningCounter: environmentsVariables.REACT_APP_SHOW_OPENING_COUNTER!?.toLowerCase() === 'true',
    torus: JSON.parse(environmentsVariables.REACT_APP_TORUS!),
    tradeAddress: environmentsVariables.REACT_APP_TRADE_ADDRESS!,
    uniswapInputCurrency: environmentsVariables.REACT_APP_UNISWAP_INPUT_CURRENCY!,
    uniswapOutputCurrency: environmentsVariables.REACT_APP_UNISWAP_OUTPUT_CURRENCY!,
    uniswapDefaultExchangeAmount: +environmentsVariables.REACT_APP_UNISWAP_DEFAULT_EXCHANGE_AMOUNT!,
    useMarketMocks: environmentsVariables.REACT_APP_USE_MARKET_MOCKS!?.toLowerCase() === 'true',
    disableAvatarUpload: environmentsVariables.REACT_APP_DISABLE_AVATAR_UPLOAD?.toLowerCase() === 'true',
    // Percentage value of royalties fee for Trade contract sales  0.0 - 100.0
    royaltiesFee: Number(environmentsVariables.REACT_APP_ROYALTIES_FEE!),
    // Percentage value of trade fee for Trade contract sales\ 0.0 - 100.0
    tradeFee: Number(environmentsVariables.REACT_APP_TRADE_FEE!),
  };
};
