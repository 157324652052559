import React, { useMemo } from 'react';

import { SOCIAL_MEDIA_LINKS } from 'constants/social-media-links.constants';

import { ReactComponent as BSCScan } from 'assets/social-media/bscscan.svg';
import { ReactComponent as CoinMarketCap } from 'assets/social-media/coinmarketcap.svg';
import { ReactComponent as Discord } from 'assets/social-media/discord.svg';
import { ReactComponent as DiscordBorder } from 'assets/social-media/discord_border.svg';
import { ReactComponent as DiscordGradient } from 'assets/social-media/discord_gradient.svg';
import { ReactComponent as Facebook } from 'assets/social-media/facebook.svg';
import { ReactComponent as FacebookBorder } from 'assets/social-media/facebook_border.svg';
import { ReactComponent as FacebookGradient } from 'assets/social-media/facebook_gradient.svg';
import { ReactComponent as Instagram } from 'assets/social-media/instagram.svg';
import { ReactComponent as InstagramBorder } from 'assets/social-media/instagram_border.svg';
import { ReactComponent as InstagramGradient } from 'assets/social-media/instagram_gradient.svg';
import { ReactComponent as Medium } from 'assets/social-media/medium.svg';
import { ReactComponent as Telegram } from 'assets/social-media/telegram.svg';
import { ReactComponent as TelegramBorder } from 'assets/social-media/telegram_border.svg';
import { ReactComponent as TelegramGradient } from 'assets/social-media/telegram_gradient.svg';
import { ReactComponent as Twitter } from 'assets/social-media/twitter.svg';
import { ReactComponent as TwitterBorder } from 'assets/social-media/twitter_border.svg';
import { ReactComponent as TwitterGradient } from 'assets/social-media/twitter_gradient.svg';
import { ReactComponent as YouTube } from 'assets/social-media/youtube.svg';
import { ReactComponent as YouTubeBorder } from 'assets/social-media/youtube_border.svg';
import { ReactComponent as YouTubeGradient } from 'assets/social-media/youtube_gradient.svg';

type SocialMediaType =
  | 'facebook'
  | 'youtube'
  | 'instagram'
  | 'discord'
  | 'twitter'
  | 'medium'
  | 'coinmarketcap'
  | 'bscscan'
  | 'telegram';
type IconType = 'border' | 'gradient' | undefined;

interface SocialsBarProps {
  order: Array<{ type: SocialMediaType; icon?: IconType }>;
  className?: string;
  iconClassName?: string;
}

const createSocialMediaLink = (iconComponent: JSX.Element, href: string, className?: string): JSX.Element => (
  <a href={href} target="_blank" className={className} rel="noreferrer">
    {iconComponent}
  </a>
);

const socialMediaIcons: {
  [key in SocialMediaType]?: { default: JSX.Element; border?: JSX.Element; gradient?: JSX.Element };
} = {
  facebook: { default: <Facebook />, border: <FacebookBorder />, gradient: <FacebookGradient /> },
  youtube: { default: <YouTube />, border: <YouTubeBorder />, gradient: <YouTubeGradient /> },
  instagram: { default: <Instagram />, border: <InstagramBorder />, gradient: <InstagramGradient /> },
  discord: { default: <Discord />, border: <DiscordBorder />, gradient: <DiscordGradient /> },
  twitter: { default: <Twitter />, border: <TwitterBorder />, gradient: <TwitterGradient /> },
  medium: { default: <Medium /> },
  coinmarketcap: { default: <CoinMarketCap /> },
  bscscan: { default: <BSCScan /> },
  telegram: { default: <Telegram />, border: <TelegramBorder />, gradient: <TelegramGradient /> },
};

const createSocialMediaComponent = (
  mediaType: SocialMediaType,
  iconType?: IconType,
  mediaLink?: string,
  classNames?: string
): JSX.Element | string | null => {
  const mediaIcon = socialMediaIcons[mediaType];
  if (!mediaLink || !mediaIcon) return null;

  const icon =
    iconType === 'border' && mediaIcon.border
      ? mediaIcon.border
      : iconType === 'gradient' && mediaIcon.gradient
      ? mediaIcon.gradient
      : mediaIcon.default;
  return createSocialMediaLink(icon, mediaLink, classNames);
};

const SocialsBar: React.FC<SocialsBarProps> = ({ order, className = '', iconClassName = '' }) => {
  const classNames = [className];

  const socialMediaLinks: { [key in SocialMediaType]?: string } = SOCIAL_MEDIA_LINKS;

  const socialMediaComponents: { [key in SocialMediaType]?: (icon?: IconType) => JSX.Element | string | null } =
    useMemo(() => {
      const iconClassNames = [iconClassName];
      const components: { [key in SocialMediaType]?: (icon?: IconType) => JSX.Element | string | null } = {};
      for (let mediaType in socialMediaIcons) {
        components[mediaType as SocialMediaType] = (iconType: IconType) =>
          createSocialMediaComponent(
            mediaType as SocialMediaType,
            iconType,
            socialMediaLinks[mediaType as SocialMediaType],
            iconClassNames.join(' ')
          );
      }
      return components;
    }, [socialMediaLinks, iconClassName]);

  return (
    <ul className={classNames.join(' ')}>
      {order.map((item, index) => {
        const Component: any = socialMediaComponents[item.type];
        return Component ? <li key={index}>{Component(item.icon)}</li> : null;
      })}
    </ul>
  );
};

export default SocialsBar;
