import ScrollInAnimationItem from '../../../components/scrollInAnimationItem/ScrollInAnimationItem';

import styles from './LandingRoadmapSection.module.scss';
import { LANDING_ROADMAP } from '../constants/landing-roadmap.contant';
import ListDot from '../../../components/listDot/ListDot';

interface ILandingRoadmapSectionItemProps {
  title: string;
  checked: boolean;
}

const LandingRoadmapSectionItem = ({ title, checked }: ILandingRoadmapSectionItemProps) => {
  const classNames = [
    styles['roadmap-section__list-item'],
    checked ? styles['roadmap-section__list-item--checked'] : '',
  ].join(' ');

  return (
    <li className={classNames}>
      <ListDot className={styles['roadmap-section__list-dot']} checked={checked} />
      <p>{title}</p>
    </li>
  );
};

const LandingRoadmapSection = () => {
  return (
    <section className={['landing-page-container', styles['section']].join(' ')}>
      <div className={styles['content']}>
        <ScrollInAnimationItem className={styles['title']} delay="0.1s" threshold={0.3}>
          <h2>Roadmap</h2>
        </ScrollInAnimationItem>

        <div className={`landing-main-text ${styles['roadmap-sections']}`}>
          {LANDING_ROADMAP.map((section) => (
            <div className={styles['roadmap-section']} key={section.title}>
              <ScrollInAnimationItem delay="0.2s" threshold={0.3}>
                <p className={styles['roadmap-section__title']}>{section.title}</p>
              </ScrollInAnimationItem>
              <ul className={styles['roadmap-section__list']}>
                {section.items.map((item, itemIndex) => (
                  <ScrollInAnimationItem
                    delay={`${0.1 * itemIndex + 0.3}s`}
                    threshold={0.3}
                    key={`${section.title}-${item.title}`}
                  >
                    <LandingRoadmapSectionItem title={item.title} checked={item.checked} />
                  </ScrollInAnimationItem>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={styles['roadmap-summary']}>
          <div className={`landing-main-text ${styles['roadmap-summary__item']}`}>
            <ListDot checked={false} className={styles['roadmap-summary__dot']} />
            <p>In progress</p>
          </div>
          <div className={`landing-main-text ${styles['roadmap-summary__item']}`}>
            <ListDot checked className={styles['roadmap-summary__dot']} />
            <p>Completed</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingRoadmapSection;
