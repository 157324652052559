import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { DEFAULTS } from 'constants/defaults.constant';

import SocialsBar from 'components/socials/socialsBar';

import { ReactComponent as ArrowSVG } from 'assets/arrow.svg';

const SocialMediaAndLinks = () => {
  const { t } = useTranslation();

  const className =
    'tw-duration-400 tw-text-sm tw-leading-4 tw-text-menuLinkColor tw-transition-colors tw-ease-in-out hover:tw-text-white hover:tw-underline';

  return (
    <div className="tw-mr-1.5">
      <p className="tw-mb-3 tw-text-center tw-font-semibold tw-uppercase tw-text-white">Follow us on</p>
      <SocialsBar
        order={[
          { type: 'discord', icon: 'border' },
          { type: 'instagram', icon: 'border' },
          { type: 'youtube', icon: 'border' },
          { type: 'facebook', icon: 'border' },
          { type: 'twitter', icon: 'border' },
        ]}
        className="list-reset tw-flex tw-justify-center tw-gap-4"
        iconClassName="tw-h-7.5 tw-w-7.5"
      />
      <nav className="tw-mb-2.5 tw-mt-7.5">
        <p className="tw-mb-2.5 tw-text-center tw-text-sm tw-font-medium tw-text-white">{t('copyright')}</p>
        <ul className="list-reset tw-flex tw-flex-wrap tw-justify-center tw-gap-x-2.5 tw-gap-y-2.5">
          <li>
            <Link className={className} to={DEFAULTS.privacyPolicyLink} target="_blank">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className={className} to={DEFAULTS.termsOfServiceLink} target="_blank">
              Terms of Service
            </Link>
          </li>
          <li>
            <Link className={twMerge(className, 'group')} to={'/contact'} target="_blank" rel="noreferrer">
              Contact Us
              <ArrowSVG className="tw-ml-1.5 group-hover:tw-text-white" />
            </Link>
          </li>
          <li>
            <Link className={twMerge(className, 'group')} to={'/'} target="_blank" rel="noreferrer">
              Main Site
              <ArrowSVG className="tw-ml-1.5 group-hover:tw-text-white" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SocialMediaAndLinks;
