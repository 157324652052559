import styled from 'styled-components';

export const RouletteContainer = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  object-fit: contain;
  z-index: 5;
  pointer-events: none;

  @media screen and (min-width: 768px) {
    width: 445px;
    height: 445px;
  }
`;

export const RotationContainer = styled.div<{
  classKey: string;
  startSpinningTime: number;
  continueSpinningTime: number;
  stopSpinningTime: number;
  startRotationDegrees: number;
  finalRotationDegrees: number;
}>`
  width: 100%;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ startRotationDegrees }) => startRotationDegrees}deg);

  &.started-spinning {
    animation: spin-${({ classKey }) => classKey} ${({ startSpinningTime }) => startSpinningTime / 1000}s cubic-bezier(
          0.71,
          0,
          0.96,
          0.9
        ) 0s 1 normal forwards running,
      continueSpin-${({ classKey }) => classKey} ${({ continueSpinningTime }) => continueSpinningTime / 1000}s linear ${({
          startSpinningTime,
        }) => startSpinningTime / 1000}s 1 normal forwards running,
      stopSpin-${({ classKey }) => classKey} ${({ stopSpinningTime }) => stopSpinningTime / 1000}s cubic-bezier(
          0,
          0,
          0.35,
          1.02
        ) ${({ startSpinningTime, continueSpinningTime }) => (startSpinningTime + continueSpinningTime) / 1000}s 1 normal
        forwards running;
  }

  @keyframes spin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${({ startRotationDegrees }) => startRotationDegrees}deg);
    }
    to {
      transform: rotate(${({ startRotationDegrees }) => 360 + startRotationDegrees}deg);
    }
  }
  @keyframes continueSpin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${({ startRotationDegrees }) => startRotationDegrees}deg);
    }
    to {
      transform: rotate(${({ startRotationDegrees }) => 360 + startRotationDegrees}deg);
    }
  }
  @keyframes stopSpin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${({ startRotationDegrees }) => startRotationDegrees}deg);
    }
    to {
      transform: rotate(${({ finalRotationDegrees }) => 1440 + finalRotationDegrees}deg);
    }
  }
`;
