import { FC } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { IPropsWithChildrenAndClassName } from 'types/common.interface';

import VenueBackground from 'components/venueBackground/VenueBackground';

import { useClamped } from 'hooks/useClamped';

import { parseThousandWithEmpty } from 'utils/parser.utils';

import { ReactComponent as ImageBgSvg } from 'assets/nft-card/nft-card-image-background.svg';

import { useNftCardSize } from '../hooks';
import { useNftCardContext } from '../nftCardContext/NftCardContext';
import NftCardMarker from '../nftCardMarker/NftCardMarker';
import NftCardOverbid from '../nftCardOverbid/NftCardOverbid';
import NftCardPanel from '../nftCardPanel/NftCardPanel';
import NftCardShares from '../nftCardShares/NftCardShares';
import NftCardToggleButton from '../nftCardToggleButton/NftCardToggleButton';
import NftCategoryIdBadge from '../nftCategoryIdBadge/NftCategoryIdBadge';

const renderShares = (shares: string | number | undefined) => {
  if (typeof shares === 'number') return parseThousandWithEmpty(shares);

  return shares;
};

export interface INftCardFrontProps extends IPropsWithChildrenAndClassName {
  toggleable?: boolean;
  asLinkTo?: string;
  isOverbid?: boolean;
}

const NftCardFront: FC<INftCardFrontProps> = ({ children, toggleable = true, asLinkTo, isOverbid }) => {
  const { asset } = useNftCardContext();
  const { isCardLarge, isCardMedium, isCardSmall } = useNftCardSize();
  const isNotSmallCard = isCardLarge || isCardMedium;
  const { ref, clamped } = useClamped();

  const cardFront = (
    <>
      <div className="tw-relative tw-overflow-hidden tw-rounded-2xl tw-bg-backgroundColorLight">
        {isOverbid && <NftCardOverbid />}
        <NftCategoryIdBadge />
        {toggleable && <NftCardToggleButton />}
        <VenueBackground
          backgroundAnimationUrl={asset?.animationUrl}
          backgroundImageUrl={asset?.imageUrl}
          className="tw-w-full tw-pb-[100%]"
          autoPlay={isNotSmallCard}
        />
        <div
          className={twMerge(
            'tw-relative tw-grid',
            // before element added to prevent space when card is scaled
            'before:tw-absolute before:-tw-top-px before:tw-left-0.5 before:tw-right-0.5 before:tw-h-0.5 before:tw-w-[calc(100%_-_4px)] before:tw-bg-backgroundColorLight before:tw-content-[""]',
            isCardLarge && 'tw-gap-3 tw-p-5 tw-pt-10',
            isCardMedium && 'tw-gap-1.5 tw-p-3 tw-pt-7.5',
            isCardSmall && 'tw-gap-1.5 tw-p-3 tw-pt-5'
          )}
        >
          <ImageBgSvg className="tw-absolute tw-inset-x-0 -tw-top-4.5 tw-w-full" />
          <NftCardMarker />
          {asset?.shares && (
            <div className="tw-mx-auto tw-w-fit tw-rounded-2xl tw-bg-backgroundColorDark tw-px-2.5 tw-py-1.5">
              <NftCardShares size="sm">{renderShares(asset.shares)}</NftCardShares>
            </div>
          )}
          <NftCardPanel
            className={twMerge(
              isNotSmallCard && 'tw-h-[68px] tw-p-2.5',
              isCardSmall && 'tw-h-[54px] tw-px-2.5 tw-py-2'
            )}
          >
            <div
              ref={ref}
              className={twMerge(
                'tw-mb-1 tw-flex tw-items-center tw-justify-center',
                isNotSmallCard && 'tw-h-7.5',
                isCardSmall && 'tw-h-5'
              )}
            >
              <p
                className={twMerge(
                  'tw-line-clamp-2 tw-px-0.5 tw-font-bold tw-uppercase tw-text-white',
                  isNotSmallCard && 'tw-text-[26px]/[20px]',
                  isCardSmall && clamped ? 'tw-text-[18px]/[14px]' : 'tw-text-[24px]/[19px]'
                )}
              >
                {asset?.name}
              </p>
            </div>
            <span
              className={twMerge(
                'tw-font-semibold tw-text-menuLinkColor',
                isNotSmallCard && 'tw-text-[13px]/[12px]',
                isCardSmall && 'tw-text-[12px]/[12px]'
              )}
            >
              {asset?.address}
            </span>
          </NftCardPanel>
          {isNotSmallCard && children}
        </div>
      </div>
      {isCardSmall && children}
    </>
  );

  return asLinkTo ? (
    <Link className="backface-hidden tw-relative tw-z-0 tw-block" to={asLinkTo}>
      {cardFront}
    </Link>
  ) : (
    <div className="backface-hidden tw-relative tw-z-0">{cardFront}</div>
  );
};

export default NftCardFront;
