import { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes.constant';
import { RAILWAY_URL } from 'constants/urls';

import { ScrollContext } from 'contexts/ScrollContext';

import Button from 'components/buttons/Button';
import FreeRMVControl from 'components/freeRMVControl/FreeRMVControl';
import MenuElement from 'components/menu/menuElement/MenuElement';
import MenuToggle from 'components/menu/menuToggle/MenuToggle';
import MenuWalletButton from 'components/menu/menuWalletButton/MenuWalletButton';
import SocialsBar from 'components/socials/socialsBar';

import { useHomePageNavigate } from 'hooks/useHomePageNavigate';

import RMV from 'assets/icons/RMV.png';
import RealityGamesLogo from 'assets/logos/reality-metaverse-logo.png';

import styles from './LandingMobileHeader.module.scss';

interface ILandingMobileHeader {
  className?: string;
  isStaking: boolean;
}

const LandingMobileHeader = ({ className, isStaking }: ILandingMobileHeader) => {
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);
  const { activeScrollSection, executeScroll } = useContext(ScrollContext);
  const { navigateToHomePage } = useHomePageNavigate();

  useEffect(() => {
    setMenuVisible(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener('keydown', onEscape, false);

    return () => {
      document.removeEventListener('keydown', onEscape, false);
    };
  }, []);

  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setMenuVisible(false);
    }
  };

  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className={`${className || ''} ${styles['menu']}`}>
      <div className={styles['menu__logo']}>
        <Link to="/" aria-label="Landing page">
          <span className="avatar">
            <img src={RealityGamesLogo} alt="Reality Metaverse" />
          </span>
        </Link>
      </div>
      <SocialsBar
        order={[
          { type: 'medium' },
          { type: 'bscscan' },
          { type: 'coinmarketcap' },
          { type: 'telegram' },
          { type: 'discord' },
          { type: 'twitter' },
        ]}
        className={styles['nav-bar_social__list']}
        iconClassName={styles['nav-bar__social___icon']}
      />
      <nav className={`${styles['nav']} ${menuVisible ? styles['nav--expanded'] : ''}`}>
        <Scrollbars style={{ width: '100%', height: '100%' }}>
          <ul className={styles['nav-list']}>
            <li>
              <MenuElement
                title="RMV"
                link="/"
                icon={<img src={RMV} alt="RMV" />}
                textClassName={styles['nav-text']}
                onClick={() => executeScroll('rmv')}
                active={activeScrollSection === 'rmv'}
              />
            </li>
            <li>
              <MenuElement
                title="Reality NFT"
                link="/"
                textClassName={styles['nav-text']}
                onClick={() => executeScroll('realityNFT')}
                active={activeScrollSection === 'realityNFT'}
              />
            </li>
            <li>
              <MenuElement
                title="Games"
                active={location.pathname === ROUTES.GAMES}
                link={ROUTES.GAMES}
                textClassName={[styles['nav-text'], styles['nav-text__staking']].join(' ')}
              />
            </li>
            <li>
              <MenuElement
                title="Staking"
                active={location.pathname === ROUTES.PERIODICAL_STAKING}
                link={ROUTES.PERIODICAL_STAKING}
                textClassName={[styles['nav-text'], styles['nav-text__staking']].join(' ')}
              />
            </li>
            <li>
              <MenuElement
                title="Whitepaper"
                link="/"
                textClassName={styles['nav-text']}
                onClick={() => executeScroll('whitepaper')}
                active={activeScrollSection === 'whitepaper'}
              />
            </li>
            <li className={styles['nav-list__item']}>
              <MenuElement
                title="News"
                active={location.pathname === ROUTES.PROMOTIONS}
                link={ROUTES.PROMOTIONS}
                textClassName={styles['nav-text']}
              />
            </li>
          </ul>
        </Scrollbars>
      </nav>
      {menuVisible && <div className={styles['nav__backdrop']} onClick={() => setMenuVisible(false)}></div>}
      <div className="tw-flex tw-items-center">
        <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
          <FreeRMVControl className="tw-hidden tw-h-[38px] md:tw-block min-[1650px]:tw-h-[48px]" size="medium-small" />
          <Button
            className={`${styles['banner-button']} ${styles['banner-button__text']}`}
            size="medium"
            onClick={navigateToHomePage}
          >
            <span className={styles['text']}>Launch app</span>
          </Button>
        </div>
        {isStaking && (
          <div className="tw-ml-5 tw-hidden md:tw-block">
            <MenuWalletButton size="medium-small" />
          </div>
        )}
      </div>
      <button
        className={`button--styleless ${styles['menu-toggle']} ${menuVisible ?? styles['menu-toggle--collapsed']}`}
        onClick={toggleMenuVisibility}
        aria-label="Open navigation menu"
      >
        <MenuToggle isActive={menuVisible}></MenuToggle>
      </button>
    </div>
  );
};

export default LandingMobileHeader;
