export const SOCIAL_MEDIA_LINKS = {
  twitter: 'https://twitter.com/Realitymeta',
  youtube: 'https://www.youtube.com/channel/UChHhktjclrF4wQjZs72UKQg',
  facebook: 'https://www.facebook.com/realitymetaverse',
  instagram: 'http://www.instagram.com/reality__metaverse',
  discord: 'https://discord.gg/reality-meta',
  telegram: 'https://t.me/RealityMeta',
  coinmarketcap: 'https://coinmarketcap.com/currencies/reality-metaverse/',
  medium: 'https://medium.com/@Realitymeta',
  bscscan: 'https://etherscan.io/address/0x423352F2c6E0E72422B69Af03aba259310146d90',
};